var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-sheet', {
    staticClass: "pr-5 grey lighten-5"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 0,
      "md": 1
    }
  }), _c('v-col', {
    staticClass: "pt-10 mr-5 hidden-sm-and-down",
    attrs: {
      "cols": 2,
      "lg": 1
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": 130
    }
  }, [_vm.imageEdit ? _c('Loader', {
    staticClass: "mr-14"
  }) : _vm._e(), !_vm.imageEdit ? _c('img', {
    attrs: {
      "src": _vm.imageUrl ? _vm.imageUrl : require('./../assets/images/icon/no_dp.png')
    }
  }) : _vm._e()], 1), _c('input', {
    ref: "avatarFile",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.uploadAttachmentSave
    }
  }), _vm.edit ? _c('v-btn', {
    staticClass: "mt-n12 ml-5",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openImage
    }
  }, [_c('img', {
    attrs: {
      "src": require('./../assets/images/icon/Edit.svg')
    }
  })]) : _vm._e()], 1), this.$slots.subtitle ? _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 8
    }
  }, [_c('v-row', {
    staticClass: "grey lighten-5"
  }, [_c('v-col', {
    attrs: {
      "cols": 10
    }
  }, [_c('span', {
    staticClass: "grey lighten-5 font-weight-bold text-truncate ml-2",
    "class": {
      'display-1': _vm.$vuetify.breakpoint.lgAndUp,
      'headline': _vm.$vuetify.breakpoint.mdAndDown
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])])], 1), _vm._t("subtitle")], 2) : _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 8
    }
  }, [_c('v-row', {
    staticClass: "grey lighten-5 mt-10"
  }, [_c('v-col', {
    attrs: {
      "cols": 10
    }
  }, [_c('span', {
    staticClass: "grey lighten-5 font-weight-bold text-truncate",
    "class": {
      'display-1': _vm.$vuetify.breakpoint.lgAndUp,
      'headline': _vm.$vuetify.breakpoint.mdAndDown
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])])], 1)], 1), _c('v-col', {
    staticClass: "pt-10 hidden-sm-and-down",
    attrs: {
      "cols": 2,
      "lg": 1
    }
  }, [_vm.appendImageUrl ? _c('v-avatar', {
    attrs: {
      "tile": "",
      "size": 130
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.appendImageUrl
    }
  })]) : _vm._e()], 1)], 1)], 1), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.loadingError ? _c('error', [_vm._v("An error ocurred while loading this page. Please try again")]) : !_vm.loadingCompleted ? _c('loader') : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 10,
      "offset-md": 1,
      "offset-sm": 0
    }
  }, [_vm._t("default")], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }