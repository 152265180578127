<template>
  <span>
    <v-sheet class="pr-5 grey lighten-5">
      <v-row>
        <v-col :cols="0" :md="1">
        </v-col>
        <v-col :cols="2" :lg="1" class="pt-10 mr-5 hidden-sm-and-down">
          <v-avatar :size="130">
          <Loader class="mr-14" v-if="imageEdit"></Loader>
            <img v-if="!imageEdit" :src="imageUrl ? imageUrl : require('./../assets/images/icon/no_dp.png')" />
          </v-avatar>
					<input type="file" ref="avatarFile" style="display: none" accept="image/*" @change="uploadAttachmentSave" />
					<v-btn v-if="edit" class="mt-n12 ml-5" icon @click="openImage">
						<img :src="require('./../assets/images/icon/Edit.svg')" />
					</v-btn>
        </v-col>
        <v-col v-if="this.$slots.subtitle" :cols="12" :md="8">
            <v-row class="grey lighten-5">
              <v-col :cols="10">
                  <span
                    class="grey lighten-5 font-weight-bold text-truncate ml-2"
                    :class="{'display-1': $vuetify.breakpoint.lgAndUp, 'headline': $vuetify.breakpoint.mdAndDown}"
                  >
                    {{title}}
                  </span>
              </v-col>
            </v-row>
            <slot name="subtitle"></slot>
        </v-col>
        <v-col v-else :cols="12" :md="8">
            <v-row class="grey lighten-5 mt-10">
              <v-col :cols="10">
                  <span
                    class="grey lighten-5 font-weight-bold text-truncate"
                    :class="{'display-1': $vuetify.breakpoint.lgAndUp, 'headline': $vuetify.breakpoint.mdAndDown}"
                  >
                    {{title}}
                  </span>
              </v-col>
            </v-row>
        </v-col>
        <v-col :cols="2" :lg="1" class="pt-10 hidden-sm-and-down">
          <v-avatar v-if="appendImageUrl" tile :size="130">
            <img :src="appendImageUrl" />
          </v-avatar>
        </v-col>
      </v-row>
    </v-sheet>

    <v-container fluid>
      <error v-if="loadingError">An error ocurred while loading this page. Please try again</error>
      <loader v-else-if="!loadingCompleted"></loader>
      <v-row v-else>
        <v-col :cols="12" :md="10" :offset-md="1" :offset-sm="0">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "@/components/Loader.vue";
import Error from "@/components/Error.vue";
import {ApiCallStatus} from "../utils/api.js";


export default {
  name: "page",
  editDone: false,
	data: function() {
		return {
			rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      imageEdit: false,
		};
	},
  props: {
    title: {
      type: String,
      required: true,
    },
    apiStatusList: {
        type: Array,
        required: false,
    },
    imageUrl: {
        type: String,
        required: false,
    },
    appendImageUrl: {
        type: String,
        required: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    loginRequired: {
				type: Boolean,
				required: false,
				default: false,
		}
  },
	methods: {
		...mapActions("user", ["uploadUserImage", "editProfile"]),
		async uploadAttachmentSave(event) {
      this.imageEdit = true;
      // console.log("files", await this.$refs.avatarFile.files[0].text());
      this.encodeImageFileAsURL(await this.$refs.avatarFile, this.uploadUserImage);
    },
    checkUser() {
			//console.log("LoginRequired", this.loginRequired, this.user);
			if(!this.loginRequired) return;
			if(!this.user || this.user.isAnnonymous) return this.$router.push("/community/login");
		},
		openImage(event) {
			//console.log("Event .. ", event);
			console.log(this.$refs.avatarFile);
			this.$refs.avatarFile.click();
    },
    async encodeImageFileAsURL(element, callback) {
      var file = element.files[0];
      var reader = new FileReader();
      reader.onloadend = function() {
        callback({ image: reader.result.replace(/^data:image.+;base64,/, '') });
      }
      reader.readAsDataURL(file);
    },
	},
  computed: {
    ...mapState('user', ['profileImageUrl', 'user']),
    loadingCompleted() {
      var completed = this.apiStatusList
        .reduce((t, s) => t && (s == ApiCallStatus.SUCCESS), true);
			return completed;
    },
    loadingError() {
      return this.apiStatusList
        .reduce((t, s) => t || (s == ApiCallStatus.ERROR), false);
    },
  },
  watch: {
    profileImageUrl(i) {
      console.log('Profile Image Changed', i);
      this.editProfile().then(_ => this.imageEdit = false);
    },
    loginRequired: function() {
			this.checkUser();
		},
		user: function() {
			this.checkUser();
		},
  },
  components: {
    Loader,
    Error,
  },
};
</script>
<style scoped>
</style>
